import type { PageProps } from './ui-components/types';

import withHead from '@app/components/with-head';

import {
  AliasCardHeader,
  AliasCardContent,
  AliasCardFooter,
  AliasCardSecurityAlert,
  AliasForm,
} from './ui-components';
import { setSettings, getProps } from './ui-components/services';

export const getServerSideProps = getProps;

export const setPageSettings = setSettings;

export const AliasMobile = ({
  title,
  isDonationUser,
  siteId,
  image,
  url,
  brand,
  currencyId,
  alias,
}: PageProps) => (
  <div className="calculator-container">
    <AliasForm
      min={isDonationUser ? 1000 : 0}
      currencyId={currencyId}
      alias={alias}
      siteId={siteId}
    >
      {({ onChange, entryMode, loading, isFormValid }) => (
        <div className="card-calculator">
          <div>
            <div className="alias__header">
              <AliasCardHeader image={image} url={url} brand={brand} />
            </div>
            <div className="alias__content">
              <AliasCardContent
                title={title}
                amountFieldProps={{
                  entryMode,
                  onChange,
                  currencyId,
                }}
              />
            </div>
            <div className="alias__content">
              <AliasCardFooter
                buttonProps={{
                  loading,
                  disabled: !isFormValid,
                }}
              />
            </div>
          </div>

          <AliasCardSecurityAlert />
        </div>
      )}
    </AliasForm>
  </div>
);

export const hydrate = 'hydrateRoot';

export default withHead(AliasMobile);
